import React, { useEffect, useMemo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRouter } from 'next/router';

import ArrowDown from '@/assets/icon/arrow_down.svg';

import i18nextConfig from '../../../next-i18next.config';
import { LanguageSwitchLink } from '../link';

const LanguageSelector = () => {
  const router = useRouter();
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale;

  const languageText = useMemo(() => {
    return currentLocale === 'zh' ? '繁體中文' : '简体中文';
  }, [currentLocale]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className='title-16r flex items-center gap-1'>
          {languageText}
          <ArrowDown />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className='title-16r mt-4 flex min-w-[220px] flex-col gap-1 rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <DropdownMenu.Item asChild>
            <LanguageSwitchLink
              locale='zh'
              className='block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
              label='繁體中文'
            >
              繁體中文
            </LanguageSwitchLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <LanguageSwitchLink
              locale='cn'
              className='block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
              label='简体中文'
            >
              简体中文
            </LanguageSwitchLink>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default LanguageSelector;
