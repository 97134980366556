import { HTMLProps, PropsWithChildren } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

interface IProps extends HTMLProps<HTMLAnchorElement> {
    skipLocaleHandling?: boolean
    locale?: string
}

const LinkComponent = ({ children, skipLocaleHandling = false, ...rest }: PropsWithChildren<IProps>) => {
    const router = useRouter()
    const locale = (rest.locale || router.query.locale || '') as string

    let href = rest.href || router.asPath
    if (href.indexOf('http') === 0) skipLocaleHandling = true
    if (locale && !skipLocaleHandling) {
        href = href ? `/${locale}${href}` : router.pathname.replace('[locale]', locale)
    }

    return <>
        <Link href={href} legacyBehavior>
            <a {...rest}>{children}</a>
        </Link>
    </>
}

export default LinkComponent
export { default as LanguageSwitchLink } from './language-switch-link'