import React, { PropsWithChildren, useMemo, useState } from 'react';
import { RiCloseLargeLine as CloseIcon } from 'react-icons/ri';
import { TfiAlignJustify as NavIcon } from 'react-icons/tfi';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Menu, useIndexQuery, useMenusQuery } from '@/api';
import { SecondaryButton } from '@/components/button';
import LanguageSelector from '@/components/languageSelector';
import Link from '@/components/link';
import Sidebar from '@/components/sidebar';

const NavButton = ({
  href,
  currentPath,
  className,
  children,
}: PropsWithChildren<{
  href: string;
  currentPath: string;
  className?: string;
}>) => {
  const isActive = currentPath.includes(href);

  return (
    <Link
      href={href}
      className={clsx(
        className,
        'title-16r cursor-pointer select-none',
        isActive ? 'text-pri-100' : 'text-bk-100'
      )}
      target='_self'
    >
      {children}
    </Link>
  );
};

const Header = () => {
  const { pathname } = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { data: indexData, isLoading: indexLoading } = useIndexQuery();
  const { data: menuData, isLoading: menusLoading } = useMenusQuery();

  const menus = useMemo(() => {
    if (!menuData) return [];
    // sort by priority
    return menuData.menus.sort((a, b) => b.priority - a.priority);
  }, [menuData]);

  const handleContactClick = () => {
    if (!indexData) return;

    window.open(indexData.contactUrl, '_blank');
  };

  const handleSidebarShow = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarHide = () => {
    setIsSidebarOpen(false);
  };

  const getNavButtons = () => {
    return menus.map((nav) => (
      <NavButton key={nav.title} href={`/${nav.href}`} currentPath={pathname}>
        {nav.title}
      </NavButton>
    ));
  };

  return (
    <div>
      <div className='relative border-b border-border-base'>
        <div className='lg:h-content container flex h-[80px] flex-row items-center justify-between lg:py-4'>
          <div className='flex flex-row items-center gap-x-12'>
            {/* logo */}
            <Link
              className='relative h-[28px] w-[105px] lg:h-[56px] lg:w-[213px]'
              href='/'
              target='_self'
            >
              <Image
                src='/image/header/logo.png'
                alt='logo'
                fill
                //width={213}
                //height={56}
              />
            </Link>
            {/* nav */}
            <div
              className='hidden flex-row items-center gap-x-8 md:flex'
              role='navigation'
            >
              {getNavButtons()}
            </div>
          </div>
          <div className='hidden flex-row items-center gap-x-8 lg:flex'>
            <SecondaryButton onClick={handleContactClick} className='px-8 py-3'>
              聯絡我們
            </SecondaryButton>
            <LanguageSelector />
          </div>
          <div className='flex gap-x-4 md:hidden'>
            <LanguageSelector />
            <button onClick={handleSidebarShow}>
              {isSidebarOpen ? <CloseIcon /> : <NavIcon />}
            </button>
          </div>
        </div>
      </div>
      <Sidebar isShow={isSidebarOpen} onNavigate={handleSidebarHide} />
    </div>
  );
};

export default Header;
