import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'next-i18next';

import {
  ActivitiesResponse,
  ActivityResponse,
  BooksResponse,
  FAQsResponse,
  FeedbacksResponse,
  IndexResponse,
  JourneyResponse,
  JourneysResponse,
  MenusResponse,
  PageResponse,
  StoresResponse,
} from './types';

// export all types
export * from './types';

// Axios instance
const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ENDPOINT,
});

function useLocalizedQuery<TData = unknown, TError = unknown>(
  endpoint: string,
  queryKey: string | readonly unknown[],
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) {
  const { i18n } = useTranslation();
  const localeParam = i18n.language === 'cn' ? 'cn' : 'zh';

  return useQuery<TData, TError>({
    queryKey: [queryKey, localeParam],
    queryFn: async () => {
      const { data } = await apiClient.get<TData>(
        `${endpoint}?locale=${localeParam}`
      );
      return data;
    },
    ...options,
  });
}

// 定义所有 API 查询函数
export const useIndexQuery = () =>
  useLocalizedQuery<IndexResponse>('/index', 'index');
export const useMenusQuery = () =>
  useLocalizedQuery<MenusResponse>('/menus', 'menus');
export const useBooksQuery = () =>
  useLocalizedQuery<BooksResponse>('/books', 'books');
export const useFAQsQuery = () =>
  useLocalizedQuery<FAQsResponse>('/faqs', 'faqs');
export const useJourneysQuery = () =>
  useLocalizedQuery<JourneysResponse>('/journeys', 'journeys');
export const useJourneyQuery = (index: number) =>
  useLocalizedQuery<JourneyResponse>(`/journey/${index}`, `journey-${index}`);
export const useActivitiesQuery = () =>
  useLocalizedQuery<ActivitiesResponse>('/activities', 'activities');
export const useActivityQuery = (index: number) =>
  useLocalizedQuery<ActivityResponse>(
    `/activity/${index}`,
    `activity-${index}`
  );
export const useFeedbacksQuery = () =>
  useLocalizedQuery<FeedbacksResponse>('/feedbacks', 'feedbacks');
export const useStoresQuery = () =>
  useLocalizedQuery<StoresResponse>('/stores', 'stores');
export const usePageQuery = (slug: string) =>
  useLocalizedQuery<PageResponse>(`/page/${slug}`, `page-${slug}`);

/*

// 查詢 /index API
export const useIndexQuery = () => {
  return useQuery<IndexResponse>({
    queryKey: ['index'],
    queryFn: async () => {
      const { data } = await apiClient.get<IndexResponse>('/index');
      return data;
    },
  });
};

// 查詢 /menus API
export const useMenusQuery = () => {
  return useQuery<MenusResponse>({
    queryKey: ['menus'],
    queryFn: async () => {
      const { data } = await apiClient.get<MenusResponse>('/menus');
      return data;
    },
  });
};

// 查詢 /books API
export const useBooksQuery = () => {
  return useQuery<BooksResponse>({
    queryKey: ['books'],
    queryFn: async () => {
      const { data } = await apiClient.get<BooksResponse>('/books');
      return data;
    },
  });
};

// 查詢 /faqs API
export const useFAQsQuery = () => {
  return useQuery<FAQsResponse>({
    queryKey: ['faqs'],
    queryFn: async () => {
      const { data } = await apiClient.get<FAQsResponse>('/faqs');
      return data;
    },
  });
};

// 查詢 /journeys API
export const useJourneysQuery = () => {
  return useQuery<JourneysResponse>({
    queryKey: ['journeys'],
    queryFn: async () => {
      const { data } = await apiClient.get<JourneysResponse>('/journeys');
      return data;
    },
  });
};

// 查詢 /journey/{index} API
export const useJourneyQuery = (index: number) => {
  return useQuery<JourneyResponse>({
    queryKey: [`journey-${index}`],

    queryFn: async () => {
      const { data } = await apiClient.get<JourneyResponse>(
        `/journey/${index}`
      );
      return data;
    },
  });
};

// 查詢 /activities API
export const useActivitiesQuery = () => {
  return useQuery<ActivitiesResponse>({
    queryKey: ['activities'],
    queryFn: async () => {
      const { data } = await apiClient.get<ActivitiesResponse>('/activities');
      return data;
    },
  });
};

// 查詢 /activity/{index} API
export const useActivityQuery = (index: number) => {
  return useQuery<ActivityResponse>({
    queryKey: [`activity-${index}`],
    queryFn: async () => {
      const { data } = await apiClient.get<ActivityResponse>(
        `/activity/${index}`
      );
      return data;
    },
  });
};

// 查詢 /feedbacks API
export const useFeedbacksQuery = () => {
  return useQuery<FeedbacksResponse>({
    queryKey: ['feedbacks'], // 使用陣列作為 queryKey
    queryFn: async () => {
      const { data } = await apiClient.get<FeedbacksResponse>('/feedbacks');
      return data;
    },
  });
};

// 查詢 /stores API
export const useStoresQuery = () => {
  return useQuery<StoresResponse>({
    queryKey: ['stores'], // 使用陣列作為 queryKey
    queryFn: async () => {
      const { data } = await apiClient.get<StoresResponse>('/stores');
      return data;
    },
  });
};

// 查詢 /page/{slug} API
export const usePageQuery = (slug: string) => {
  return useQuery<PageResponse>({
    queryKey: [`page-${slug}`], // 使用 slug 作為 queryKey
    queryFn: async () => {
      const { data } = await apiClient.get<PageResponse>(`/page/${slug}`);
      return data;
    },
  });
};
*/
