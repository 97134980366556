import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useIndexQuery, useMenusQuery } from '@/api';
import { SecondaryButton } from '@/components/button';
import Link from '@/components/link';

interface ISidebarProps {
  isShow: boolean;
  onNavigate: () => void;
}

const Sidebar = ({ isShow, onNavigate }: ISidebarProps) => {
  const { data: indexData, isLoading: indexLoading } = useIndexQuery();
  const { data: menuData, isLoading: menuLoading } = useMenusQuery();

  const { pathname } = useRouter();

  const handleContactClick = () => {
    if (!indexData) return;

    window.open(indexData.contactUrl, '_blank');
  };

  const menus = useMemo(() => {
    if (!menuData) return [];
    // sort by priority
    return menuData.menus.sort((a, b) => b.priority - a.priority);
  }, [menuData]);

  return (
    <div
      className={clsx(
        'fixed left-0 right-0 top-[150px] z-20 h-[calc(100%-150px)] border-t border-border-base bg-white p-5 shadow-lg duration-300',
        isShow ? 'translate-x-0' : 'translate-x-full'
      )}
    >
      <div className='flex flex-col gap-6 overflow-y-auto py-5'>
        {menus.map((menu) => (
          <div
            key={menu.id}
            onClick={onNavigate}
            className={clsx(
              'title-20m',
              pathname.includes(menu.href) && 'text-pri-100'
            )}
          >
            <Link href={`/${menu.href}`}>{menu.title}</Link>
          </div>
        ))}
      </div>
      <div className='absolute bottom-6 left-4 right-4 flex flex-col gap-6'>
        <SecondaryButton
          onClick={handleContactClick}
          className='px-8 py-3 text-center'
        >
          聯絡我們
        </SecondaryButton>
        {/* <LanguageSelector /> */}
      </div>
    </div>
  );
};

export default Sidebar;
