// react query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';

import Header from '@/components/header';
import { Footer, Header as OriginHeader } from '@/components/navigation';

import '@/styles/globals.css';
// Create a client
const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation();
  return (
    <QueryClientProvider client={queryClient}>
      <div className='min-h-lvh'>
        <Head>
          <title>{t('common.title', '出版服務')}</title>
        </Head>
        <OriginHeader />
        <Header />
        <Component {...pageProps} />
        <Footer />
      </div>
    </QueryClientProvider>
  );
};

export default appWithTranslation(MyApp);
