import Image from 'next/image';

import { scbooksUrl } from './utils';

const items = [
  {
    title: '關於我們',
    items: [
      {
        title: '認識商鼎',
        url: scbooksUrl('/about'),
      },
      {
        title: '獲獎／媒體報導',
        url: scbooksUrl('/media'),
      },
      {
        title: '投稿出版',
        url: scbooksUrl('/page/submit'),
      },
      {
        title: '來出書吧！獨立出版',
        url: scbooksUrl('/pub'),
      },
    ],
  },
  {
    title: '經銷通路',
    items: [
      {
        title: '實體書店',
        url: scbooksUrl('/stores'),
      },
      {
        title: '網路書店',
        url: scbooksUrl('/estores'),
      },
      {
        title: '電子書城',
        url: scbooksUrl('/ebookstores'),
      },
    ],
  },
  {
    title: '客服中心',
    items: [
      {
        title: '註冊新會員',
        url: scbooksUrl('/newaccount'),
      },
      {
        title: '忘記密碼',
        url: scbooksUrl('/forgetPassword'),
      },
      {
        title: '常見問題 Q&A',
        url: scbooksUrl('/qa'),
      },
      {
        title: '訂購辦法',
        url: scbooksUrl('/page/howToBuy'),
      },
      {
        title: '付款資訊',
        url: scbooksUrl('/page/payInfo'),
      },
      {
        title: '折價券說明',
        url: scbooksUrl('/page/getCoupon'),
      },
      {
        title: '勘誤服務',
        url: scbooksUrl('/page/ancillary'),
      },
      {
        title: '隱私政策',
        url: scbooksUrl('/page/privacy'),
      },
    ],
  },
  {
    title: '其他',
    items: [
      {
        title: '千華數位文化',
        url: 'https://www.chienhua.com.tw/',
        external: true,
      },
      {
        title: '商鼎@蝦皮商城',
        url: 'https://shopee.tw/jnov80atsc',
        external: true,
      },
      {
        title: '商鼎@博客來',
        url: 'https://www.books.com.tw/web/sys_puballb/books/?pubid=bstripod',
        external: true,
      },
      {
        title: '商鼎@Yahoo奇摩超級商城',
        url: 'https://tw.mall.yahoo.com/store/scbks',
        external: true,
      },
      {
        title: (
          <>
            <i className='bi bi-facebook text-[#0c88ef]'></i>
            商鼎@FB紛絲團
          </>
        ),
        url: 'https://www.facebook.com/scbkservice',
        external: true,
      },
      {
        title: (
          <>
            <i className='bi bi-youtube text-[#f00]'></i>
            商鼎@YouTube
          </>
        ),
        url: 'https://www.youtube.com/channel/UCKd2fQT3Kghx6FtOqfA0yYg',
        external: true,
      },
      {
        title: (
          <>
            <Image
              src='/image/navigation/ig-logo.png'
              alt='ig logo'
              height={16}
              width={16}
              className='inline'
            />
            商鼎@Instagram
          </>
        ),
        url: 'https://www.instagram.com/scbooks_tw/',
        external: true,
      },
      {
        title: (
          <>
            <i className='bi bi-facebook text-[#0c88ef]'></i>
            來出書吧！商鼎為你設計出版
          </>
        ),
        url: 'https://www.facebook.com/profile.php?id=100089641163338',
        external: true,
      },
    ],
  },
];

const Footer = () => {
  return (
    <footer className='container flex flex-col items-center p-4 pt-8 text-[#6C757D]'>
      <div className='flex w-full flex-col-reverse gap-6 lg:flex-row'>
        <div className='flex flex-col'>
          <Image
            src='/image/navigation/CH50-logo.png'
            alt='CH50'
            height={56}
            width={200}
            className='mb-2'
          />
          <h5 className='mb-2 text-xl font-bold text-[#585c61]'>
            商鼎數位出版有限公司
          </h5>
          <ul>
            <li>
              235 新北市中和區中山路三段 136 巷 10 弄 17 號{' '}
              <a
                href='https://goo.gl/maps/Z2jFdshhz2r5SfACA'
                className='underline'
              >
                〈地圖〉
              </a>
            </li>
            <li>電話：+886-2-2228-9070</li>
            <li>傳真：+886-2-2228-9076</li>
            <li>統一編號：24494539</li>
            <li>
              客服信箱：
              <a href='mailto://scbkservice@gmail.com' className='underline'>
                scbkservice@gmail.com
              </a>
            </li>
            <li>服務時間：週一～五 9:00～18:00</li>
          </ul>
          <p className='mt-4 opacity-70'>
            人人都可以是作家！圖文出版、數位出版、本公司能為您量身定做，用著作點亮人生。
          </p>
        </div>
        <div className='grid grid-cols-2 gap-6 md:grid-cols-4'>
          {items.map((item) => (
            <div key={item.title} className='flex flex-1 flex-col'>
              <h6 className='mb-2 font-bold text-[#585c61]'>{item.title}</h6>
              <ul>
                {item.items.map((subItem) => (
                  <li key={subItem.url}>
                    {'external' in subItem && subItem.external ? (
                      <a href={subItem.url} target='_blank' rel='noreferrer'>
                        {subItem.title}
                      </a>
                    ) : (
                      <a href={subItem.url}>{subItem.title}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <p className='mt-12 pt-4'>© 2022 商鼎數位出版有限公司</p>
    </footer>
  );
};

export default Footer;
