import { useState } from 'react';
import Image from 'next/image';

import { scbooksUrl } from './utils';

const items = [
  {
    title: '商品分類',
    url: scbooksUrl('/search/series/SC1A/1'),
  },
  {
    title: '優惠活動',
    url: scbooksUrl('/discount/0S081121_NB'),
  },
  {
    title: '活動／講座',
    url: scbooksUrl('/activity/0W061131_NB'),
  },
  {
    title: '禮品兌換',
    url: scbooksUrl('/gift'),
  },
  {
    title: '專欄',
    url: scbooksUrl('/columns'),
  },
  {
    title: '獨立出版服務',
    url: '',
  },
  {
    title: '團購服務',
    url: scbooksUrl('/buy'),
  },
  {
    title: '關於',
    url: scbooksUrl('/about'),
  },
];

const Header = () => {
  const [search, setSearch] = useState('');

  const [slideShow, setSlideShow] = useState(false);

  const handleSlideShowClick = () => {
    setSlideShow(true);
  };

  const handleCloseSlideShow = () => {
    setSlideShow(false);
  };

  return (
    <div className='h-[70px] w-full shadow'>
      <nav className='container flex px-3 py-4 text-black/55'>
        <a
          href='https://www.scbooks.com.tw/#/index'
          className='mr-4 flex items-center'
        >
          <Image
            src='/image/navigation/logo-light-long.png'
            alt='logo'
            width={160}
            height={32}
            className='hidden sm:block'
          />
          <Image
            src='/image/navigation/logo-light.png'
            alt='logo'
            width={52}
            height={32}
            className='block sm:hidden'
          />
        </a>
        <div className='hidden grow items-center justify-start lg:flex'>
          {items.map((item) => (
            <a
              key={item.title}
              href={item.url}
              className='p-2 [&[href=""]]:text-black/90'
            >
              {item.title}
            </a>
          ))}
        </div>
        <div className='flex grow items-center justify-end gap-4 text-2xl'>
          <div className='hidden h-10 xl:flex'>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='書名、作者、書號'
              className='rounded-l border border-[#ced4da] px-3 py-1.5 text-base'
            />
            <a
              href={scbooksUrl(`/search/keyword/${search}/1`)}
              className='flex aspect-square h-full items-center justify-center rounded-r bg-[#318FB5] text-lg text-white'
            >
              <i className='bi bi-search'></i>
            </a>
          </div>
          <div className='group relative z-10 flex size-10 xl:hidden'>
            <button className='peer flex size-full items-center justify-center text-lg'>
              <i className='bi bi-search'></i>
            </button>
            <div className='absolute -bottom-full right-0 hidden origin-top-right translate-y-1/2 bg-white px-4 py-3 shadow group-has-[:active]:block group-has-[:focus]:block'>
              <div className='flex h-10'>
                <input
                  type='text'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='書名、作者、書號'
                  className='rounded-l border border-[#ced4da] px-3 py-1.5 text-base'
                />
                <a
                  href={scbooksUrl(`/search/keyword/${search}/1`)}
                  className='flex aspect-square h-full items-center justify-center rounded-r bg-[#318FB5] text-lg text-white'
                >
                  <i className='bi bi-search'></i>
                </a>
              </div>
            </div>
          </div>
          <a href={scbooksUrl('/login')}>
            <i className='bi bi-person-circle'></i>
          </a>
          <a href={scbooksUrl('/cart')}>
            <i className='bi bi-cart'></i>
          </a>
          <div className='group z-10 block lg:hidden'>
            <button onClick={handleSlideShowClick}>
              <i className='bi bi-list'></i>
            </button>
            {slideShow && (
              <>
                <div className='fixed inset-0 bg-black/50 '></div>
                <div className='fixed bottom-0 right-0 top-0 w-[260px] bg-[#f5f5f5] text-base group-has-[:active]:block'>
                  <div className='flex h-[70px] items-center justify-between bg-white p-4'>
                    <a
                      href={scbooksUrl('/index')}
                      className='inline-flex gap-1 rounded bg-[#318FB5] px-3 py-1.5 text-white hover:bg-[#50a0c0]'
                    >
                      <i className='bi bi-house'></i>
                      商鼎數位出版
                    </a>
                    <button
                      className='size-8 p-2'
                      id='close'
                      onClick={handleCloseSlideShow}
                    >
                      <i className='bi bi-x-lg'></i>
                    </button>
                  </div>
                  <ul className='p-4'>
                    {items.map((item) => (
                      <li
                        key={item.title}
                        className='h-10 py-2 hover:text-black/90 has-[a[href=""]]:text-black/90'
                      >
                        <a href={item.url}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
